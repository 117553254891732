<template>
  <section id="RibbonBoards">
    <ExperiencesCarousel :carouselImages="carouselImages" />
  </section>
</template>

<script>
  import ExperiencesCarousel from '@/components/global/ExperiencesCarousel'

  export default {
    name: "RibbonBoards",
    components: {
      ExperiencesCarousel
    },
    data() {
      return {
        carouselImages: [
          {
            image: 'Experiences/Branding/ribbon-board.jpg',
            title: 'Upper and Lower Ribbon Boards',
            description: 'Two layers of immersive Ribbon Boards line the entire venue and are a unique way to highlight attendee names, messaging, sponsors, and much more.'
          },
          {
            image: 'Experiences/Branding/ribbon-board-2.jpg',
            title: 'Upper and Lower Ribbon Boards',
            description: 'Two layers of immersive Ribbon Boards line the entire venue and are a unique way to highlight attendee names, messaging, sponsors, and much more.'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  
</style>